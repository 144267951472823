import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  STORE_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY_DATA,
  DELETE_COMPANY,
  FETCH_COMPANIES,
  STORE_MASTER_COMPANY,
  UPDATE_MASTER_COMPANY,
  GET_MASTER_COMPANY_DATA,
  DELETE_MASTER_COMPANY,
  FETCH_MASTER_COMPANIES
} from "../actions.type";
// mutation types
import {
  SET_COMPANIES,
  SET_MASTER_COMPANIES,
  SET_ERROR
} from "../mutations.type";

const state = {
  errors: null,
  companies: [],
  mastercompanies: []
};

const getters = {
  getCompanies(state) {
    return state.companies;
  },
  getMasterCompanies(state) {
    return state.mastercompanies;
  }
};

const actions = {
  [FETCH_COMPANIES](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/company/lists")
          .then(({ data }) => {
            context.commit(SET_COMPANIES, data.data);
            resolve(data.data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [STORE_COMPANY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/company/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_COMPANY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/company/" + data.id + "/", data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_COMPANY](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("delete/company/" + id + "/")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_COMPANY_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/company", id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [FETCH_MASTER_COMPANIES](context,sp_id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/master-company/lists",'',{params:{sp_id:sp_id}})
          .then(({ data }) => {
            context.commit(SET_MASTER_COMPANIES, data.data);
            resolve(data.data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [STORE_MASTER_COMPANY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/master-company/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_MASTER_COMPANY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/master-company/" + data.id + "/", data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_MASTER_COMPANY](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("delete/master-company/" + id + "/")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_MASTER_COMPANY_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/master-company", id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  }
};

const mutations = {
  [SET_COMPANIES](state, data) {
    state.companies = data;
  },
  [SET_MASTER_COMPANIES](state, data) {
    state.mastercompanies = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
