import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  STORE_B_LOCATION,
  FETCH_B_LOCATIONS,
  UPDATE_B_LOCATION,
  DELETE_B_LOCATION,
  GET_B_LOCATION_DATA,
  FETCH_B_LOCATION_RATE_LISTS,
  UPDATE_B_LOCATION_RATE_LISTS,
  FETCH_B_LOCATION_USER,
  FETCH_B_ARCHIVAL_RATE_LISTS,
  UPDATE_B_ARCHIVAL_RATE_LISTS,
  FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER
} from "../actions.type";
// mutation types
import {
  SET_B_LOCATIONS,
  SET_B_LOCATION_RATE_LISTS,
  SET_ERROR,
  SET_B_ARCHIVAL_RATE_LISTS
} from "../mutations.type";

const state = {
  errors: null,
  locations: [],
  locationRateLists: []
};

const getters = {
  getBLocations(state) {
    return state.locations;
  },
  getBRateLists(state) {
    return state.locationRateLists;
  },
  getBArchivalRateLists(state) {
    return state.locationArchivalRateLists;
  }
};

const actions = {
  [FETCH_B_LOCATIONS](context,status) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/location/lists";
        ApiService.get(url,'',{params:{status:status}})
          .then(({ data }) => {
            context.commit(SET_B_LOCATIONS, data);
            resolve(data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [FETCH_B_LOCATION_USER](context, pk) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("location/user-list", pk)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_B_LOCATION](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/location/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_B_LOCATION](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/location/" + data.id + "/", data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_B_LOCATION](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("delete/location/" + id + "/")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_B_LOCATION_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/location", id)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(response => {
            reject(response);
          });
      });
    }
  },
  [FETCH_B_LOCATION_RATE_LISTS](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = `get/ratelist/${id}`;
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data.data);
            context.commit(SET_B_LOCATION_RATE_LISTS, data.data);
          })
          .catch(({ err }) => {
            reject(err);
            context.commit(SET_ERROR, err);
          });
      });
    }
  },

  [FETCH_B_ARCHIVAL_RATE_LISTS](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = `get/archival-rate/${id}`;
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data.data);
            context.commit(SET_B_ARCHIVAL_RATE_LISTS, data.data);
          })
          .catch(({ err }) => {
            reject(err);
            context.commit(SET_ERROR, err);
          });
      });
    }
  },
  [UPDATE_B_LOCATION_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "update/location/rate-list/" + data.location_id + "/",
          data.rate_list
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_B_ARCHIVAL_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "update/archival-rate/" + data.location_id + "/",
          data.rate_list
        )

          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER](context, reqdata) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `location/user-list/sp/user-list/${reqdata.client_slug}/${reqdata.client_id}`
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_B_LOCATIONS](state, data) {
    state.locations = data;
  },
  [SET_B_LOCATION_RATE_LISTS](state, data) {
    state.locationRateLists = data;
  },
  [SET_B_ARCHIVAL_RATE_LISTS](state, data) {
    state.locationArchivalRateLists = data;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
