import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_CLIENT_JOB_LIST,
  FETCH_CLIENT_JOB_DETAILS,
  FETCH_CLIENT_JOB_APPOINTMENTLIST,
  //   UPDATE_CLINET_JOB_DETAILS,
  SAVE_CLIENT_JOB_DETAILS,
  SEND_CLIENT_JOB_MAIL
} from "../actions.type";
// mutation types
import {
  SET_CLIENT_JOB_LIST,
  SET_CLIENT_JOB_DETAILS,
  SET_ERROR
} from "../mutations.type";

const state = {
  errors: null,
  jobs: [],
  jobDetails: []
};

const getters = {
  getJobList(state) {
    return state.jobs;
  },
  getJobDetails(state) {
    return state.jobDetails;
  }
};

const actions = {
  [FETCH_CLIENT_JOB_LIST](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/location-job/list/?page=" + data.page;
        ApiService.post(url, data)
          .then(({ data }) => {
            context.commit(SET_CLIENT_JOB_LIST, data.data);
            resolve(data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [SAVE_CLIENT_JOB_DETAILS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "location-job/create/";
        ApiService.post(url, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_CLIENT_JOB_DETAILS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/location-job/";
        ApiService.post(url, data)
          .then(({ data }) => {
            context.commit(SET_CLIENT_JOB_DETAILS, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_CLIENT_JOB_APPOINTMENTLIST](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/location-job/appointment/list/?page=" + data.page;
        ApiService.post(url, data)
          .then(({ data }) => {
            context.commit(SET_CLIENT_JOB_DETAILS, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [SEND_CLIENT_JOB_MAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "send-mail/location-job/";
        ApiService.post(url, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data.msg);
          });
      });
    }
  }
};

const mutations = {
  [SET_CLIENT_JOB_LIST](state, data) {
    state.jobs = data;
  },

  [SET_CLIENT_JOB_DETAILS](state, data) {
    state.jobDetails = data;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
