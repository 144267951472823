import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import {
  DELETE_SCHEDULES,
  FETCH_C_APPOINTMENT_BASE_PLAN,
  FETCH_SCHEDULES,
  FETCH_SCHEDULE_BASE_PLAN,
  STORE_APPOINTMENT_FROM_APPLICANT,
  STORE_C_APPOINTMENT_BASE_PLAN,
  STORE_SCHEDULE_BASEPLAN,
  STORE_SCHEDULE_HOLIDAY,
  FETCH_SELF_APPOINMENT,
  UPDATE_APPOINTMENT_STATUS,
  MAKE_INVOICE_AFTER_ONLINE_PAYEMENT,
  UPDATE_APPOINTMENT_PAYMENT_STATUS,
  FETCH_INVOICE_DETAILS,
  FETCH_ENROLLMENT_INVOICE_DETAILS,
  STORE_APPOINTMENT,
  STORE_APPOINTMENT_ID,
  STORE_APPOINTMENT_APPLICANT_INFO,
  STORE_APPOINTMENT_APPLICANT_INFO_ID,
  STORE_APPLICANT_PERSONAL_INFO,
  APPOINTMENT_REFUND,
  STORE_APPOINTMENT_NEW_BASE_PLAN,
  STORE_HOLIDAY,
  FETCH_HOLIDAYS,
  REMOVE_HOLIDAYS,
  FETCH_NEW_BASE_PLAN,
  STORE_ADDITIONAL_SERVICE,
  STORE_APPOINTMENT_ARCHIVE,
  CHECK_HOLIDAY,
  FETCH_LOCATION_NEW_BASE_PLAN,
  FETCH_LOCATION_HOLIDAYS,
  APPOINTMENT_LOGS,
  STORE_DRUG_APPOINTMENT_APPLICANT_INFO,
  SUCCESS_DRUG_TESTING_PAYEMENT,
  RELOAD_APPOINTMENT,
  OTP_VERIFICATION,
  VERIFY_OTP_SMS,
  VERIFY_OTP_EMAIL,
  RESEND_OTP_EMAIL,
  RESEND_OTP_SMS
} from "../actions.type";
import {
  SET_CLIENT_DETAILS,
  SET_SCHEDULE_TEMP_DATA,
  SET_APPOINTMENT_ID,
  SET_APPLICATION_INFO_ID,
  SET_APPT_TOKEN,
  SET_HOLIDAYS,
  SET_APPOINTMENT_LOGS,
  SET_APPICANTS,
  SET_OTP_FOR_EMAIL_VERIFY,
  SET_OTP_FOR_SMS_VERIFY
} from "../mutations.type";
// import {
//   SET_F_CLIENTS,
//   SET_ERROR,
//   SET_F_CLIENT_NAME,
//   SET_F_CLIENT_RATE,
//   SET_F_ALERT_MSG,
//   SET_RATE_TOTAL_AMOUNT,
//   SET_ADD_RATE_QTY,
//   SET_CHECK_F_ABBREVIATION_CODE,
//   UPDATE_ABBREVIATION_CODE
// } from "../mutations.type";

const state = {
  scheduleTemData: [],
  clientData: null,
  appointmentId: 0,
  applicantId: 0,
  persionalId: 0,
  appt_token: 0,
  holidays: [],
  appointmentLog: [],
  applicants: [],
  is_sms_verification: false,
  is_email_verification: false
};
const getters = {
  getScheduleTemData(state) {
    return state.scheduleTemData;
  },
  getApplicants(state) {
    return state.applicants;
  },
  getappointmentLog(state) {
    return state.appointmentLog;
  },
  getClientData(state) {
    return state.clientData;
  },
  getHolidays(state) {
    return state.holidays;
  },

  getAppointmentId(state) {
    return state.appointmentId;
  },
  getApplicantId(state) {
    return state.applicantId;
  },
  getPersonalId(state) {
    return state.persionalId;
  },
  getApptToken(state) {
    return state.appt_token;
  },
  getsmsverification(state) {
    return state.is_sms_verification;
  },
  getemailverification(state) {
    return state.is_email_verification;
  }
};
const actions = {
  [STORE_C_APPOINTMENT_BASE_PLAN](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("appointment/store/base-plan", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [APPOINTMENT_LOGS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/${data.app_id}/appointment_logs`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_APPOINTMENT_LOGS, data.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },

  [STORE_APPOINTMENT_NEW_BASE_PLAN](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("store/weeklybase-schedule/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_HOLIDAY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("add/holiday-list/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [CHECK_HOLIDAY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("check/holiday-appointments/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [REMOVE_HOLIDAYS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/holiday-list/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_NEW_BASE_PLAN]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/weeklybase-schedule`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_LOCATION_NEW_BASE_PLAN](context, id) {
    // console.log(id);
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/location/weeklybase-schedule/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_C_APPOINTMENT_BASE_PLAN]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("appointment/base-plans")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SCHEDULES]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("appointment/schedules")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_HOLIDAYS](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/holiday-list")
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_HOLIDAYS, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_LOCATION_HOLIDAYS](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/location/holiday-list/${id}`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_HOLIDAYS, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_SCHEDULE_HOLIDAY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("appointment/schedule/make-holiday", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_SCHEDULE_BASEPLAN](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("appointment/schedules/store/base-plan", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_SCHEDULES](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("appointment/schedules/delete", id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_SCHEDULE_BASE_PLAN](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("appointment/schedules/base-plans", id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_APPOINTMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [STORE_APPOINTMENT_ID](context, data) {
    state.appointmentId = data.selected_appointment_id;
  },
  [STORE_APPOINTMENT_APPLICANT_INFO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [OTP_VERIFICATION](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [VERIFY_OTP_SMS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [VERIFY_OTP_EMAIL](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [RESEND_OTP_EMAIL](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [RESEND_OTP_SMS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [STORE_DRUG_APPOINTMENT_APPLICANT_INFO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("store/new-next-appointment/drug-testing/", data.data)
        .then(({ data }) => {
          if (data.code == 200) {
            context.commit(SET_APPICANTS, data.selected_appointments);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [STORE_ADDITIONAL_SERVICE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("self/additional-card/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_APPOINTMENT_ARCHIVE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("self/appoiment-archive/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [STORE_APPOINTMENT_APPLICANT_INFO_ID](context, data) {
    state.applicantId = data.applicant_id;
  },
  [STORE_APPLICANT_PERSONAL_INFO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [STORE_APPOINTMENT_FROM_APPLICANT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(data.url, data.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [MAKE_INVOICE_AFTER_ONLINE_PAYEMENT](contest, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("appointment/applicant/payment/success", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [SUCCESS_DRUG_TESTING_PAYEMENT](contest, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("appointment/drug-testing-payment/success", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [FETCH_SELF_APPOINMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("appointment/self", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_APPOINTMENT_PAYMENT_STATUS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("status/update/invoice/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [APPOINTMENT_REFUND](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("refund/appointment/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [FETCH_INVOICE_DETAILS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("get/invoice/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [FETCH_ENROLLMENT_INVOICE_DETAILS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("get/enrollment/invoice/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_APPOINTMENT_STATUS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("appointment/status/update", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [RELOAD_APPOINTMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("reload-appointment/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};
const mutations = {
  [SET_SCHEDULE_TEMP_DATA](state, data) {
    state.scheduleTemData = data;
  },
  [SET_APPOINTMENT_LOGS](state, data) {
    state.appointmentLog = data;
  },
  [SET_CLIENT_DETAILS](state, data) {
    state.clientData = data;
  },
  [SET_APPLICATION_INFO_ID](state, id) {
    state.applicantId = id;
  },
  [SET_APPOINTMENT_ID](state, id) {
    state.appointmentId = id;
  },
  [SET_APPT_TOKEN](state, id) {
    state.appt_token = id;
  },
  [SET_HOLIDAYS](state, data) {
    state.holidays = data.data;
  },
  [SET_APPICANTS](state, data) {
    state.applicants = data;
  },
  [SET_OTP_FOR_SMS_VERIFY](state, data) {
    state.is_sms_verification = data;
  },
  [SET_OTP_FOR_EMAIL_VERIFY](state, data) {
    state.is_email_verification = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
