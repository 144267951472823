import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import {
  GET_SERVICE_PROVIDER_LIST,
  CREATE_SERVICE_PROVIDER
} from "../actions.type";
import { SET_SERVICE_PROVIDER } from "../mutations.type";

const state = {
  serviceprovider: []
};
const getters = {
  getserviceProviders(state) {
    return state.serviceprovider;
  }
};
const actions = {
  [GET_SERVICE_PROVIDER_LIST](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/service-providers/list")
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_SERVICE_PROVIDER, data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [CREATE_SERVICE_PROVIDER](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/service-providers/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};
const mutations = {
  [SET_SERVICE_PROVIDER](state, data) {
    state.serviceprovider = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
