import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_APIKEY,
  CREATE_APIKEY,
  GENERATE_APIKEY,
  GET_APIKEY,
  GET_API_KEY,
  DELETE_APIKEY,
  CREATE_API_KEY_DEVICES,
  DELETE_APIKEY_DEVICE,
  FETCH_APIKEY_VERSION,
  UPDATE_APIKEY_VERSION,
  UPDATE_APIKEY_VERSION_LISTS,
  UNSCHEDULE_APIKEY_VERSION,
  FETCH_APIKEY_LOGS
} from "../actions.type";
import { SETAPIKEY, SETAPIKEYVERSIONLIST } from "../mutations.type";
const state = {
  apikeys: [],
  apikeysversion: []
};

const getters = {
  getApikeyData(state) {
    return state.apikeys;
  },
  getApikeyVersionData(state) {
    // console.log(state.apikeysversion);
    return state.apikeysversion;
  }
};

const actions = {
  [FETCH_APIKEY](context, data) {
    var data_ = data;
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/api-key/list/`, data_)

          .then(({ data }) => {
            // console.log(data);
            resolve(data);
            context.commit(SETAPIKEY, data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [FETCH_APIKEY_VERSION](context, data) {
    var data_ = data;
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/api-key/version-list/`, data_)

          .then(({ data }) => {
            // console.log(data);
            resolve(data);
            context.commit(SETAPIKEYVERSIONLIST, data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [GENERATE_APIKEY]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`generate/apikey`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_APIKEY](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/api-key/${id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [CREATE_API_KEY_DEVICES](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`add/api-key/devices/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [GET_API_KEY](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/api-key/devices/${id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_APIKEY](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/apikey/${id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [CREATE_APIKEY](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`add/api-key/`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_APIKEY_VERSION](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`update/api-key/version/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [UNSCHEDULE_APIKEY_VERSION](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`unschedule/api-key/version/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [DELETE_APIKEY_DEVICE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/api-key/device/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_APIKEY_VERSION_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/apikey-version/list/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_APIKEY_LOGS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("get/apikey/logs/list/?page=" + data.page, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SETAPIKEY](state, value) {
    state.apikeys = value;
  },
  [SETAPIKEYVERSIONLIST](state, value) {
    state.apikeysversion = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
