import Vue from "vue";
import Router from "vue-router";
import frontendRoute from "./frontend";
import clientauth from "./clientauth";
import backendRoute from "./backend";

Vue.use(Router);

var router = new Router({
  mode: "history",
  routes: [
    ...frontendRoute,
    ...clientauth,
    {
      path: "client/",
      name: "client",
      component: () => import("@/components/layouts/app"),
      children: [...backendRoute]
    },
    {
      path: "*",
      name: "page.not.found",
      component: () => import("@/pages/404")
    },
    {
      path: "/page-not-found",
      name: "error.page",
      component: () => import("@/pages/404")
    }
  ]
});

export default router;
