import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import axios from "axios";
import {
  FETCH_ENROLLMENT,
  ENROLLMENT_LOGS,
  TRANSMITSTATUSCHANGE,
  FETCH_PDF_TCN,
  FETCH_ARCHIVAL_ENROLLMENT,
  ASSIGN_ARCHIVAL_ENROLLMENT,
  CHANGE_ARCHIVAL_DATE,
  ASSIGN_ENROLLMENT_COMPANY,
  FETCH_ADMIN_ENROLLMENT,
  DOWNLOAD_ENROLLMENT_FILE,
  CREATE_COMPANY_INVOICE,
  REPRINT_COMPANY_INVOICE,
  FETCH_COMPANY_INVOICE_DETAILS,
  FETCH_COMPANY_INVOICE_APP_DETAIL
} from "../actions.type";
import { SET_ENROLLMENT_LOGS } from "../mutations.type";
// import {
//   SET_F_CLIENTS,
//   SET_ERROR,
//   SET_F_CLIENT_NAME,
//   SET_F_CLIENT_RATE,
//   SET_F_ALERT_MSG,
//   SET_RATE_TOTAL_AMOUNT,
//   SET_ADD_RATE_QTY,
//   SET_CHECK_F_ABBREVIATION_CODE,
//   UPDATE_ABBREVIATION_CODE
// } from "../mutations.type";

const state = {
  appointmentId: 0,
  enrollmentId: 0,
  enrollmentLog: [],
  enrollment: []
};
const getters = {
  getenrollmentLog(state) {
    return state.enrollmentLog;
  }
};
const actions = {
  [FETCH_PDF_TCN](context, tcn) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/pdf/${tcn}`, "", {
          responseType: "blob",
          timeout: 30000
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [CREATE_COMPANY_INVOICE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Access-Control-Expose-Headers"] = "*";
        ApiService.get(
          `create/company/invoice/pdf/?company=${data.company}&appointments=${data.appointments}`,
          "",
          {
            responseType: "blob",
            timeout: 30000
          }
        )
          .then(data => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [REPRINT_COMPANY_INVOICE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Access-Control-Expose-Headers"] = "*";
        ApiService.get(
          `reprint/company/invoice/?invoice_no=${data.invoice_no}`,
          "",
          {
            responseType: "blob",
            timeout: 30000
          }
        )
          .then(data => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [DOWNLOAD_ENROLLMENT_FILE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`download/admin/enrollment-tcn/`, data, {
          responseType: "blob",
          timeout: 30000
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [FETCH_COMPANY_INVOICE_DETAILS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("get/company/invoice/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [FETCH_COMPANY_INVOICE_APP_DETAIL](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("get/company/invoice/appdetail/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  [TRANSMITSTATUSCHANGE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("update/enrollment/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [FETCH_ADMIN_ENROLLMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("fetch/admin/enrollment-tcn/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  [ENROLLMENT_LOGS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/enrollment/logs/${data.enrollment_id}`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_ENROLLMENT_LOGS, data.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [FETCH_ENROLLMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/enrollment/${data.enrollment_id}/detail`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [FETCH_ARCHIVAL_ENROLLMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/archival/${data.enrollment_id}/detail`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [ASSIGN_ARCHIVAL_ENROLLMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("assign/enrollment/archivals/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [CHANGE_ARCHIVAL_DATE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("update/archival-date/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [ASSIGN_ENROLLMENT_COMPANY](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("assign/enrollment-company/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
};
const mutations = {
  [SET_ENROLLMENT_LOGS](state, data) {
    state.enrollmentLog = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
