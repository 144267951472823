import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import auth from "./modules/auth.module";
import config from "./modules/config.module";
import htmlClass from "./modules/htmlclass.module";
import Services from "./modules/services.module";
import Clients from "./modules/clients.module";
import Appointment from "./modules/appointment.module";
import Master from "./modules/master.module";
import Setting from "./modules/setting.module";
import Company from "./modules/company.module";
import Service from "./modules/service.module";
import User from "./modules/user.module";
import Location from "./modules/location.module";
import Log from "./modules/log.module";
import Reschedule from "./modules/reschedule.module";
import Serviceprovider from "./modules/serviceprovider.module";
import dashboadModule from "./modules/dashboad.module";
import reportModule from "./modules/report.module";
import groupModule from "./modules/group.module";
import apikeyModule from "./modules/apikey.module";
import Enrollment from "./modules/enrollment.module";
import Roles from "./modules/roles.module";
import Archivals from "./modules/archivals.module";
import Jobs from "./modules/jobs.module.js";
import ServiceOri from "./modules/serviceori.module.js";
import Promocode from "./modules/promocode.modules";

import MobileLocation from "./modules/mobilelocation.module";

export default new Vuex.Store({
  modules: {
    auth,
    config,
    htmlClass,
    Services,
    Clients,
    Appointment,
    Master,
    Setting,
    Company,
    Service,
    User,
    Location,
    Log,
    Reschedule,
    Serviceprovider,
    reportModule,
    dashboadModule,
    groupModule,
    apikeyModule,
    Enrollment,
    Roles,
    Archivals,
    Jobs,
    MobileLocation,
    ServiceOri,
    Promocode
  }
});
