import mobileLocationRoute from "./mobilelocation";
export default [
  {
    path: "/",
    component: () => import("@/components/layouts/frontendapp"),
    children: [
      ...mobileLocationRoute,
      {
        path: "/sp/:client_slug",
        name: "frontend.home",
        component: () => import("@/pages/frontend/home")
      },
      {
        path: "/sp/:client_slug/support",
        name: "frontend.support",
        component: () => import("@/pages/frontend/support")
      },
      {
        path: "/:client_slug/locations/:slug",
        name: "frontend.agent.locations",
        component: () => import("@/pages/frontend/agent/location")
      },
      {
        path: "/:client_slug/appointment/:id/book-now",
        name: "frontend.booknow",
        component: () => import("@/pages/frontend/booknow/index")
      },
      {
        path: "/:client_slug/appointment/success",
        name: "client.appointment.success",
        component: () => import("@/pages/frontend/booknow/final")
      },
      {
        path: "/:client_slug/appointment/view",
        name: "client.appointment.view",
        component: () => import("@/pages/frontend/reportview")
      },
      {
        path: "/:client_slug/appointment/department/citizenship/:id",
        name: "client.appointment.citizenship",
        component: () => import("@/pages/frontend/citizen/index")
      },
      {
        path: "/:client_slug/appointment/disclosure/:id",
        name: "client.appointment.disclosure",
        component: () => import("@/pages/frontend/citizen/index")
      }
      // {
      //   path: "/mobilereg/:slug",
      //   name: "frontend.mobilereg.booknow",
      //   component: () => import("@/pages/frontend/mobilereg/booknow"),
      // },
    ]
  }
];
