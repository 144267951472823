import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { FETCH_PAYMENT_LOG } from "../actions.type";
// import { SET_PAYMENT_LOG } from "../mutations.type";
const state = {};

const getters = {};
const actions = {
  [FETCH_PAYMENT_LOG](context, data = null) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = `get/log/payment?page=${data.page}`;
        ApiService.post(url, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  }
};

const mutations = {};
export default {
  state,
  actions,
  mutations,
  getters
};
