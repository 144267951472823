import ApiService from "@/core/services/api.service";

// action types
import {
  FETCH_MOBILE_LOCATION_JOB,
  STORE_MOBILE_LOCATION_APPOITMENT
} from "../actions.type";
// mutation types
import { SET_MOBILE_LOCATION_JOB } from "../mutations.type";

const state = {
  errors: null,
  mobileLocationJobList: null,
  citizenTitle: "",
  applicants: [
    {
      id: 0,
      appt_token: "",
      applicant: {
        id: "",
        fname: "",
        mname: "",
        lname: "",
        email: "",
        zipcode: "",
        dob_d: "",
        dob_m: "",
        dob_y: "",
        reason: "",
        direct_obs_req: 0,
        ssn: "",
        state: "",
        cemail: "",
        phone_no: "",
        address1: "",
        address2: "",
        city: "",
        dial_code: "+1"
      }
    }
  ]
};

const getters = {
  getMobileLocationJobList(state) {
    return state.mobileLocationJobList;
  },

  getMobileAppointApplicants(state) {
    return state.applicants;
  }
};

const actions = {
  //store/mobile-location/appointment/
  [FETCH_MOBILE_LOCATION_JOB](context, slug) {
    return new Promise((resolve, reject) => {
      let url = "get/location-job";
      ApiService.get(url, slug)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_MOBILE_LOCATION_JOB, data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [STORE_MOBILE_LOCATION_APPOITMENT](context, data) {
    return new Promise((resolve, reject) => {
      let url = "store/mobile-location/appointment/";
      ApiService.post(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_MOBILE_LOCATION_JOB](state, data) {
    state.mobileLocationJobList = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
