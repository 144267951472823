import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";

import {
  SET_LAYOUT_CONFIG,
  RESET_LAYOUT_CONFIG,
  OVERRIDE_LAYOUT_CONFIG,
  OVERRIDE_PAGE_LAYOUT_CONFIG
} from "../actions.type";

import { SET_BREADCRUMB } from "../mutations.type";

// mutation types

export default {
  state: {
    config: config,
    initial: config,
    breadcrumbs: [],
    aspectRatioheight: 180,
    aspectRatiowidth: 270,
    canvasheight: 180,
    canvaswidth: 270
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: state => (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue);
    },
    getBreadcrumbs(state) {
      return state.breadcrumbs;
    },
    getAspectheight(state) {
      return state.aspectRatioheight;
    },
    getAspectwidth(state) {
      return state.aspectRatiowidth;
    },
    getCanvasheight(state) {
      return state.canvasheight;
    },
    getCanvaswidth(state) {
      return state.canvaswidth;
    }
  },
  actions: {
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload);
    },

    /**
     * Reset the config at the initial state
     * @param state
     */
    [RESET_LAYOUT_CONFIG](state) {
      state.commit(RESET_LAYOUT_CONFIG);
    },

    /**
     * Reset the config using saved config in the cache by the layout builder
     * @param state
     */
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.commit(OVERRIDE_LAYOUT_CONFIG);
    },

    /**
     * Override config by page level
     * @param state
     * @param payload
     */
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
    }
  },
  mutations: {
    [SET_BREADCRUMB](state, data) {
      state.breadcrumbs = data;
    },
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload;
    },
    [RESET_LAYOUT_CONFIG](state) {
      state.config = Object.assign({}, state.initial);
    },
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.config = state.initial = Object.assign(
        {},
        state.initial,
        JSON.parse(localStorage.getItem("config"))
      );
    },
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.config = merge(state.config, payload);
    }
  }
};
