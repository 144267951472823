import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import * as VueGoogleMaps from "vue2-google-maps"; // Import package
import Vue from "vue";

// action types
import {
  CHECK_AUTH,
  LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE_USER,
  FETCH_USER_PERMISSIONS,
  RESET_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  REFRESH
} from "../actions.type";
// mutation types
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_REFRESH,
  SET_ERROR,
  SET_USER,
  SET_USER_PERMISSIONS,
  CLEAR_INTERVAL,
  SET_INTERVAL
} from "../mutations.type";
import jwtService from "../../jwt.service";

const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  token: {},
  permissions: [],
  refresh_token: {},
  interval: null
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    var status = state.isAuthenticated;
    if (window.localStorage.getItem("id_token")) {
      status = true;
    } else {
      status = false;
    }

    return status;
  },
  getUserPermission(state) {
    return state.permissions;
  }
};

const actions = {
  [FETCH_USER_PERMISSIONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("user/has/permissions")
        .then(({ data }) => {
          context.commit(SET_USER_PERMISSIONS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response.data.errors);
        });
    });
  },

  [CHANGE_USER_PASSWORD](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("set-password/", {
        reset_token: data.reset_token,
        client_slug: data.client_slug,
        password: data.password
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response.data.error);
        });
    });
  },

  [RESET_USER_PASSWORD](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("password-reset/", {
        email: data.email,
        client_slug: data.client_slug
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response.data.error);
        });
    });
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("get-token/", {
        username: credentials.username,
        password: credentials.password,
        client_slug: credentials.client_slug
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.access);
          context.commit(SET_REFRESH, data.refresh);
          // context.commit(SET_INTERVAL);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject(response);
        });
    });
  },
  [REFRESH](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("token/refresh", {
        refresh: JwtService.getRefreshToken()
      })
        .then(({ data }) => {
          // if (data.data.code == "token_not_valid") {
          //   context.commit(LOGOUT);
          // }
          context.commit(SET_AUTH, data.access);
          resolve(data);
        })
        .catch(({ response }) => {
          if (
            response.data.code == "token_not_valid" &&
            response.status == 401
          ) {
            context.commit(LOGOUT);
            context.commit(CLEAR_INTERVAL, response.data);
          }
          reject(response);
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/users/", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("user/me")
          .then(({ data }) => {
            Vue.use(VueGoogleMaps, {
              load: {
                key: data.c.key,
                libraries: "places"
              }
            });
            context.commit(SET_USER, data);
            context.commit(SET_AUTH, window.localStorage.getItem("id_token"));
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(PURGE_AUTH);
            context.commit(SET_ERROR, response);
            reject(response);
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_USER_PERMISSIONS](state, data) {
    state.permissions = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    state.token = token;
    state.errors = {};
    JwtService.saveToken(token);
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_REFRESH](state, token) {
    state.refresh_token = token;
    jwtService.saveRefreshToken(token);
  },
  [SET_INTERVAL](state) {
    if (state.interval == null) {
      state.interval = setInterval(() => {
        this.dispatch(REFRESH);
      }, 1000 * 60 * 15);
    }
    return;
  },
  [CLEAR_INTERVAL](state) {
    clearInterval(state.interval);
    state.interval = null;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.token = {};
    state.refresh_token = {};
    state.errors = {};
    clearInterval(state.interval);
    state.interval = null;
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
    JwtService.destroyAppointmentSearch();
    JwtService.destroyLocalSearch();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
