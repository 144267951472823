<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "assets/sass/style.vue";
@import "assets/sass/switch";
@import "vue-select/src/scss/vue-select.scss";
$white: #ffffff;

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
.new-service {
  .cleft {
    float: left;
  }

  .form-control {
    height: calc(1.5em + 1.3rem + 15px);
    background-color: $white;
    border: none;
  }
}
.mx-datepicker {
  width: 100% !important;
  .mx-input {
    height: 40px;
    border: none;
  }
}
input {
  height: 40px;
}

.full-load {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  left: 0;
  color: white;
  width: 100%;
  height: 100%;
  background: #0000007d;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  text-align: center;
  h1 {
    position: absolute;
    top: 41%;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}

.mx-input-wrapper {
  border: 1px solid #e4e6ef;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.swal2-container {
  background-color: #000000b3 !important;
}
.form-validate-required {
  border-right: 2px solid #dc3545 !important;
}
.card-footer {
  background-color: #fff !important;
}
</style>

<script src="https://code.jquery.com/jquery-1.12.3.min.js"></script>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  created() {
    this.$store.commit(SET_BREADCRUMB, []);
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  watch: {
    $route(to) {
      var element = document.getElementById("body");
      if (to.name == "login") {
        element.classList.add("login-page-11231");
      } else {
        element.classList.remove("login-page-11231");
      }
    }
  }
};
</script>
