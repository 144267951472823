import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_ARCHIVAL,
  GET_ARCHIVAL,
  CREATE_ARCHIVAL,
  DELETE_ARCHIVAL,
  REMOVE_ARCHIVE,
  DOWNLOAD_CLIENT_ARCHIVAL_APPOINTMENT
} from "../actions.type";
import {
  SET_ARCHIVAL,
  STORE_ARCHIVAL_DATE,
  SET_ARCHIVAL_RENEW_DATE,
  SET_ARCHIVAL_CUSTOM_DATE,
  SET_ARCHIVAL_CUSTOM_PRICE,
  SET_ARCHIVAL_CUSTOM,
  SET_APP_TYPE
} from "../mutations.type";
const state = {
  archival: [],
  archival_date: "",
  archival_renew_date: "",
  app_type: "",
  custom_archival_date: "",
  custom_archival_price: 0,
  custom_archival: false
};

const getters = {
  getArchivalData(state) {
    return state.archival;
  },
  getArchivalDate(state) {
    return state.archival_date;
  },
  getArchivalRenewDate(state) {
    return state.archival_renew_date;
  },
  getAppType(state) {
    return state.app_type;
  },
  getArchivalCustomDate(state) {
    return state.custom_archival_date;
  },
  getArchivalCustomPrice(state) {
    return state.custom_archival_price;
  },
  getArchivalCustom(state) {
    return state.custom_archival;
  }
};

const actions = {
  [CREATE_ARCHIVAL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`add/archival-period/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_ARCHIVAL](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/archival-period/list`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_ARCHIVAL, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_ARCHIVAL](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/archival-period/${id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_ARCHIVAL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/archival-period/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [REMOVE_ARCHIVE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/enrollment/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DOWNLOAD_CLIENT_ARCHIVAL_APPOINTMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`download/archival-list/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_ARCHIVAL](state, value) {
    state.archival = value;
  },
  [STORE_ARCHIVAL_DATE](state, value) {
    state.archival_date = value;
  },
  [SET_ARCHIVAL_RENEW_DATE](state, value) {
    state.archival_renew_date = value;
  },
  [SET_ARCHIVAL_CUSTOM_DATE](state, value) {
    state.custom_archival_date = value;
  },
  [SET_ARCHIVAL_CUSTOM_PRICE](state, value) {
    state.custom_archival_price = value;
  },
  [SET_ARCHIVAL_CUSTOM](state, value) {
    state.custom_archival = value;
  },
  [SET_APP_TYPE](state, value) {
    state.app_type = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
