import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_B_SERVICES,
  STORE_B_SERVICE,
  UPDATE_B_SERVICE,
  DELETE_B_SERVICE,
  GET_B_SERVICE_DATA,
  FETCH_B_ARCHIVAL
} from "../actions.type";
// mutation types
import { SET_B_SERVICES, SET_ERROR, SET_B_ARCHIVAL } from "../mutations.type";

const state = {
  errors: null,
  services: [],
  archivals: []
};

const getters = {
  getBServices(state) {
    return state.services;
  },
  getBArchivals(state) {
    return state.archivals;
  }
};

const actions = {
  [FETCH_B_SERVICES](context, data = null) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = `get/services`;
        if (data !== null && data) {
          url = `get/services/?status=${data.status}`;
        }
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_B_SERVICES, data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [FETCH_B_ARCHIVAL](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/archival-period/list")
          .then(({ data }) => {
            resolve(data.data);
            context.commit(SET_B_ARCHIVAL, data);
          })
          .catch(({ response }) => {
            reject(response);
            context.commit(SET_ERROR, response);
          });
      });
    }
  },
  [STORE_B_SERVICE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/services/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_B_SERVICE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/service/" + data.id + "/", data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_B_SERVICE](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("delete/service/" + id + "/")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_B_SERVICE_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/service", id)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  }
};

const mutations = {
  [SET_B_SERVICES](state, data) {
    state.services = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_B_ARCHIVAL](state, data) {
    state.archivals = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
