import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
// import { API_URL } from "./config";
import { REFRESH } from "@/core/services/store/actions.type";
import store from "@/core/services/store";
// const axiosApiInstance = axios.create();

/**
 * Service to call HTTP request via Axios
 */
// console.log(process.env.VUE_APP_API_URL,"yoooooooooooooo")
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request>
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", res = "") {
    return Vue.axios.get(`${resource}/${slug}`, res).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    // console.log(params);
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  jwtInterceptor() {
    axios.interceptors.response.use(
      request => {
        return request;
      },
      err => {
        // const originalRequest = err.config;

        if (
          err.response.data.code == "token_not_valid" &&
          err.response.status == 401
        ) {
          store.dispatch(REFRESH).then(() => {
            // originalRequest.headers[
            //   "Authorization"
            // ] = `Bearer ${JwtService.getToken()}`;
            Vue.axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
            // return axiosApiInstance(originalRequest);
            return err;
          });
        }
        return err;
      }
    );
  }
};

export default ApiService;
