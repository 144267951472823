import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_ROLES,
  GET_ROLE,
  CREATE_ROLE,
  CREATE_ROLE_PERMISSIONS,
  FETCH_ROLE_PERMISSIONS,
  DELETE_ROLE
} from "../actions.type";
import { SET_ROLE, SET_ROLE_PERMISSIONS } from "../mutations.type";
const state = {
  role: []
};

const getters = {
  getRoleData(state) {
    return state.role;
  }
};

const actions = {
  [FETCH_ROLES](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/roles/list`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_ROLE, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_ROLE_PERMISSIONS](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/role/permissions/${id}`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_ROLE_PERMISSIONS, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [GET_ROLE](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/role/${id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_ROLE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/role/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [CREATE_ROLE_PERMISSIONS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`add/role/permissions/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  //   [DELETE_APIKEY](context, id) {
  //     if (JwtService.getToken()) {
  //       return new Promise((resolve, reject) => {
  //         ApiService.get(`delete/apikey/${id}`)
  //           .then(({ data }) => {
  //             resolve(data.data);
  //           })
  //           .catch(({ response }) => {
  //             reject(response.data);
  //           });
  //       });
  //     }
  //   },

  [CREATE_ROLE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`add/role/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_ROLE](state, value) {
    state.role = value;
  },
  [SET_ROLE_PERMISSIONS](state, value) {
    state.role_permissions = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
