import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

import {
  FETCH_F_SERVICES,
  LOCATION_SERVICE,
  NEW_APPOINTMENT,
  FRONTEND_GET_GROUP,
  FRONTEND_SERVICE_SEARCH,
  LOCATIONS,
  NEWLOCATIONSTORE
} from "../actions.type";
import {
  SET_F_SERVICES,
  SET_F_SERVICE_SLUG,
  SET_SERVICE_NAME,
  SET_NEW_APP_NO,
  SET_LOCATION_ID
} from "../mutations.type";

const state = {
  fServices: [],
  serviceSlug: "",
  serviceName: "",
  new_app: {
    app_id: "",
    appt_id: "",
    time: "",
    appt_token: "",
    date: ""
  },
  location_id: ""
};
const getters = {
  getfServices(state) {
    return state.fServices;
  },
  getLocationId(state) {
    return state.location_id;
  },

  getServiceName(state) {
    return state.serviceName;
  },
  getfServiceSlug(state) {
    return state.serviceSlug;
  },
  getNewAppNo(state) {
    return state.new_app;
  }
};
const actions = {
  [FETCH_F_SERVICES](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("services/f", slug)
        .then(({ data }) => {
          //   console.log(data);
          context.commit(SET_F_SERVICES, data.data);
          resolve(data);
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [LOCATION_SERVICE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("self/appointment/location-services/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [LOCATIONS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("get/appointment/locations/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [NEWLOCATIONSTORE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("update/appointment/location/", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [NEW_APPOINTMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("self/appointment/add-service/", data)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_NEW_APP_NO, data);
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [FRONTEND_GET_GROUP](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/group/f/${data.slug}/services/${data.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [FRONTEND_SERVICE_SEARCH](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/search/f/services/${data.slug}`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  [SET_F_SERVICES](state, data) {
    state.fServices = data;
  },
  [SET_SERVICE_NAME](state, value) {
    state.serviceName = value;
  },
  [SET_F_SERVICE_SLUG](state, slug) {
    state.serviceSlug = slug;
  },
  [SET_NEW_APP_NO](state, new_appo) {
    state.new_app.app_id = new_appo.app_id;
    state.new_app.appt_id = new_appo.appt_id;
    state.new_app.time = new_appo.time;
    state.new_app.date = new_appo.date;
    state.new_app.appt_token = new_appo.appt_token;
  },
  [SET_LOCATION_ID](state, id) {
    state.location_id = id;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
