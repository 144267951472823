import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_COMPANY_WISE_REPORT,
  FETCH_LOCATION_WISE_REPORT,
  FETCH_LOCATION_WISE_RECEIVABLE_REPORT,
  EXPORT_DATA,
  FETCH_LOCATION_JOB_WISE_REPORT,
  FETCH_SP_ORI_WISE_REPORT,
  FETCH_SP_SERVICE_REPORT,
  FETCH_SP_SERVICE_APP_REPORT,
  GENETRATE_INVOICE_MASTER,
  FETCH_INVOICE_MASTER_LIST,
  FETCH_INVOICE_MASTER_DETAIL,
  FETCH_INVOICE_MASTER_BY_ID,
  DELETE_INVOICE_MASTER,
} from "../actions.type";
import {
  SET_SEARCH,
  SET_COMPANY_INVOICE_APPOINTMENTS,
} from "../mutations.type";
const state = {
  search: null,
  invoice_appointments: [],
};

const getters = {
  getSearchDate(state) {
    return state.search == null
      ? window.localStorage.getItem("search") == null
        ? null
        : JSON.parse(window.localStorage.getItem("search"))
      : state.search;
  },
  getInvoiceAppointments(state) {
    return state.invoice_appointments;
  },
};

const actions = {
  [FETCH_COMPANY_WISE_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/company/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_LOCATION_WISE_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/location/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GENETRATE_INVOICE_MASTER](context, data) {
    // context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`create/master-invoice/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_INVOICE_MASTER_LIST](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/master-invoice/`, data)
       
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_INVOICE_MASTER_BY_ID](context, id) {
    // context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/master-invoice-by-id/${id}/`)
       
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_INVOICE_MASTER_DETAIL](context, id) {
    // context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/master-invoice-detail/${id}/`)
       
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_INVOICE_MASTER](context, invoice_master_id) {
    // context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/master-invoice/${invoice_master_id}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_LOCATION_JOB_WISE_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/location/job/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_SP_ORI_WISE_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/all/ori/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_LOCATION_WISE_RECEIVABLE_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/location/receivable-report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SP_SERVICE_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`location-service/enrollment/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SP_SERVICE_APP_REPORT](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`location-service/appointment/report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [EXPORT_DATA](context, data) {
    context.commit(SET_SEARCH, data);

    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`export/company_report/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
};

const mutations = {
  [SET_SEARCH](state, data) {
    state.search = data;
    window.localStorage.setItem("search", JSON.stringify(data));
  },
  [SET_COMPANY_INVOICE_APPOINTMENTS](state, data) {
    state.invoice_appointments = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
