export default [
  {
    path: "payment/card_pointe",
    name: "client.intehration.payment.card_pointe",
    component: () => import("@/pages/backend/integrations/payment/card_pointe"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "google/googleanalytics",
    name: "client.intehration.google.googleanalytics",
    component: () =>
      import("@/pages/backend/integrations/google/googleanalytics"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "google/mapkey",
    name: "client.intehration.google.map",
    component: () => import("@/pages/backend/integrations/google/map"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "sms/twilio",
    name: "client.intehration.sms.twilio",
    component: () => import("@/pages/backend/integrations/sms/twilio"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "email/smtp",
    name: "client.intehration.email.smtp",
    component: () => import("@/pages/backend/integrations/email/smtp"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  }
];
