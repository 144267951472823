import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import VueLodash from "vue-lodash";
import lodash from "lodash";
// import plugin
import VueToastr from "vue-toastr";
// import './pdf417_js/bcmath-min.js';
import CKEditor from "@ckeditor/ckeditor5-vue2";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(CKEditor);

// use plugin
Vue.use(VueToastr, {
  defaultTimeout: 5000,
  defaultPosition: "toast-top-full-width"
  /* OverWrite Plugin Options if you need */
});

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(DatePicker);

// import VueSwal from "vue-swal";

// Vue.use(VueSwal);

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// name is optional
Vue.use(VueLodash, lodash);

import VueMask from "v-mask";
Vue.use(VueMask);

// import { VERIFY_AUTH } from "./core/services/store/modules/auth.module";
// import store from "@/core/services/store";
// import { CHECK_AUTH,FETCH_USER_PERMISSIONS } from "./core/services/store/actions.type";

Vue.config.productionTip = false;

import VCalendar from "v-calendar";

Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});

import VueMoment from "vue-moment";
import moment from "moment-timezone";
// import Heartland from "heartland-nodejs";
// Vue.use(Heartland);

Vue.use(VueMoment, {
  moment
});

const MyGlobalFunction = {
  install(Vue) {
    (Vue.prototype.dateFormat = str => {
      return moment(str).format("MM/DD/YYYY");
    }),
      (Vue.prototype.datetimeFormat = datetime => {
        return moment(datetime).format("MM/DD/YYYY hh:mm:ss a");
      }),
      (Vue.prototype.datetimeFormatAmPm = datetime => {
        return moment(datetime).format("MM/DD/YYYY hh:mm:ss a");
      }),
      (Vue.prototype.globalSubStr = (str, num) => {
        return str.substring(0, num) + "..";
      }),
      (Vue.prototype.closeBtnLoad = ref => {
        const submitButton = this.$refs[ref];
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }),
      (Vue.prototype.loadingButton = ref => {
        const submitButton = this.$refs[ref];
        submitButton.disabled = true;
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      });
  }
};
import ScrollLoader from "vue-scroll-loader";
Vue.use(ScrollLoader);

Vue.use(MyGlobalFunction);

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// Vue 3rd party plugins
// import i18n from "@/core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

ApiService.init();
// ApiService.jwtInterceptor();

import vSelect from "vue-select";

// import { VueReCaptcha } from "vue-recaptcha-v3";
// Vue.use(VueReCaptcha, {
//   siteKey: "6Lf61WYdAAAAAKxQaXSxdTms5TAuj9_HDr_CM0t1"
// });
import axios from "axios";

Vue.component("v-select", vSelect);
import { CHECK_AUTH, LOGOUT } from "@/core/services/store/actions.type";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import jwtService from "./core/services/jwt.service";

import { SET_AUTH } from "@/core/services/store/mutations.type";
const setRefreshToken = async tokenRefreshResponse => {
  // ApiService.jwtInterceptor();
  // jwtService.saveToken(tokenRefreshResponse.data.access);
  store.commit(SET_AUTH, tokenRefreshResponse.data.access);
  return Promise.resolve();
};
// Function that will be called to refresh authorization
const refreshAuthLogic = async failedRequest => {
  store.dispatch(LOGOUT);
  let tokenRefreshResponse = await axios.post("token/refresh", {
    refresh: jwtService.getRefreshToken()
  });
  failedRequest.response.config.headers["Authorization"] =
    "Bearer " + tokenRefreshResponse.data.access;
  return setRefreshToken(tokenRefreshResponse);
};
// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);

router.beforeEach((to, from, next) => {
  //  console.log(to);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store
      .dispatch(CHECK_AUTH)
      .then(data => {
        if (store.getters.isAuthenticated) {
          if (to.name == "client.user" && data.u.roles == "staff") {
            next({
              name: "client.dashboard", // back to safety route
              params: { client_slug: data.c.slug }
            });
          }
          if (to.meta.is_admin == data.u.is_admin) {
            next();
          }
          if (to.meta.is_client == data.u.is_client && data.u.is_admin == 0) {
            next();
          } else {
            if (to.meta.is_service_provider == data.u.is_service_provider) {
              next();
            } else {
              if (store.getters.currentUser.u.is_admin == 1) {
                next();
              } else {
                next({
                  name: "client.dashboard" // back to safety route //
                });
              }
            }
          }
        } else {
          // store.commit(PURGE_AUTH);
          store.dispatch(LOGOUT).then(() => {
            next({
              name: "client.login"
            });
          });
        }
      })
      .catch(() => {
        store.dispatch(LOGOUT).then(() => {
          next({
            name: "client.login"
          });
        });
      });

    // Promise.all([store.dispatch(FETCH_USER_PERMISSIONS)]);
  } else {
    if (to.name == "client.login") {
      if (store.getters.isAuthenticated) {
        store
          .dispatch(CHECK_AUTH)
          .then(data => {
            next({
              name: "client.dashboard", // back to safety route
              params: { client_slug: data.c.slug }
            });
          })
          .catch(() => {
            store.dispatch(LOGOUT);
          });
      }
    }
    next();
  }
});
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
