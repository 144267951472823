export default [
  {
    path: "/client/login",
    name: "client.login",
    component: () => import("@/pages/backend/auth/login")
  },

  {
    path: "/client/password_reset",
    name: "client.password.reset",
    component: () => import("@/pages/backend/auth/password_reset")
  },

  {
    path: "/client/:client_slug/password_reset/:reset_token",
    name: "client.password.reset.change",
    component: () => import("@/pages/backend/auth/change_password")
  }
];
