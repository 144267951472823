import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  UPDATE_PROMOCODE,
  GET_PROMOCODE_DATA,
  FETCH_PROMOCODES,
  DELETE_PROMOCODE,
  STORE_PROMOCODE
} from "../actions.type";
// mutation types
import { SET_PROMOCODES, SET_ERROR } from "../mutations.type";

const state = {
  errors: null,
  promocodes: []
};

const getters = {
  getPromocodes(state) {
    return state.promocodes;
  }
};

const actions = {
  [FETCH_PROMOCODES](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/promo-code/list")
          .then(({ data }) => {
            context.commit(SET_PROMOCODES, data.data);
            resolve(data.data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [STORE_PROMOCODE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/promo-code/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_PROMOCODE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/promo-code/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  //   [UPDATE_COMPANY](context, data) {
  //     if (JwtService.getToken()) {
  //       return new Promise((resolve, reject) => {
  //         ApiService.post("update/company/" + data.id + "/", data.data)
  //           .then(({ data }) => {
  //             resolve(data);
  //           })
  //           .catch(({ response }) => {
  //             reject(response.data);
  //           });
  //       });
  //     }
  //   },
  [DELETE_PROMOCODE](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("delete/promo-code/" + id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_PROMOCODE_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/promo-code/" + id)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  }
};

const mutations = {
  [SET_PROMOCODES](state, data) {
    state.promocodes = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
