import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  GET_DASHBOARD_CARD_DATA,
  FETCH_DATA_FOR_GRAPH,
  FETCH_APPOITMENT_DATA_FOR_GRAPH,
  FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH
} from "../actions.type";

// mutation types
import { SET_DASHBOARD_CARD_DATA } from "../mutations.type";

const state = {
  dashboard_card_data: []
};

const getters = {
  getDashboardcardDate(state) {
    return state.dashboard_card_data;
  }
};

const actions = {
  [GET_DASHBOARD_CARD_DATA](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/${data.type}/dashboard`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_DASHBOARD_CARD_DATA, data.booking);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_DATA_FOR_GRAPH](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/${data.client}/dashboard-data/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_APPOITMENT_DATA_FOR_GRAPH](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/${data.client}/graph/dashboard/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/dashboard-location/detail/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_DASHBOARD_CARD_DATA](state, data) {
    state.dashboard_card_data = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
