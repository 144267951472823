const ID_TOKEN_KEY = "id_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const APPOINTMENT_SEARCH = "appoitmentsearch";
const LOCAL_SEARCH = "search";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const saveRefreshToken = refresh => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
};

export const destroyRefreshToken = () => {
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};
export const destroyAppointmentSearch = () => {
  window.localStorage.removeItem(APPOINTMENT_SEARCH);
};
export const destroyLocalSearch = () => {
  window.localStorage.removeItem(LOCAL_SEARCH);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  destroyAppointmentSearch,
  destroyLocalSearch
};
