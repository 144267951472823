import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_SERVICEORI,
  GET_SERVICEORI,
  CREATE_SERVICEORI,
  DELETE_SERVICEORI,
  FETCH_SERVICECODES,
  FETCH_SERVICECODES_BY_SP,
  UPDATE_SERVICECODES_BY_SP,
  CREATE_SERVICECODES,
  FETCH_SP_ORI_RATE_LISTS,
  FETCH_LOCATION_ORI_RATE_LISTS,
  UPDATE_SP_ORI_RATE_LISTS,
  UPDATE_LOCATION_ORI_RATE_LISTS,
  FETCH_LOCATION_SERVICE_RATE_LISTS,
  UPDATE_LOCATION_SERVICE_RATE_LISTS,
  FETCH_FLORIDA_SERVICEORI,
  UPDATE_DEFAULT_ORI_RATE_LISTS,
  FETCH_DEFAULT_ORI_RATE_LISTS
} from "../actions.type";
import { SET_SERVICEORI, SET_SERVICECODES } from "../mutations.type";
const state = {
  serviceori: [],
  servicecode: [],
  spratelist: []
};

const getters = {
  getServiceOriData(state) {
    return state.serviceori;
  },
  getServiceCodeData(state) {
    return state.servicecode;
  }
};

const actions = {
  [UPDATE_SP_ORI_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "update/sp-ori/rate-list/" + data.location_id + "/",
          data
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [UPDATE_LOCATION_ORI_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "update/location-ori/rate-list/" + data.location_id + "/",
          data
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [UPDATE_LOCATION_SERVICE_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/location-service/rate-list/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SP_ORI_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/sp-ori/ratelist/" + data.id + "/" + data.code;
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [FETCH_LOCATION_ORI_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/location-ori/ratelist/" + data.id + "/" + data.code;
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },

  [FETCH_LOCATION_SERVICE_RATE_LISTS]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = `get/location-service/ratelist`;
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },

  [CREATE_SERVICEORI](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`add/serviceori/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SERVICEORI](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/serviceori/list/`, data)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_SERVICEORI, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_FLORIDA_SERVICEORI]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/serviceori/list/fdle`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_SERVICEORI](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/serviceori/${id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_SERVICEORI](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`delete/serviceori/${data}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [CREATE_SERVICECODES](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`create/service-codes/`, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SERVICECODES](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/service-codes`)
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_SERVICECODES, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_DEFAULT_ORI_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "update/default-ori/rate-list/" + data.location_id + "/",
          data
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_DEFAULT_ORI_RATE_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        let url = "get/default-ori/ratelist/" + data.code;
        ApiService.get(url)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [FETCH_SERVICECODES_BY_SP](context,id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/service-codes/"+id)
          .then(({ data }) => {
            resolve(data);
            // context.commit(SET_SERVICECODES_BY_SP, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_SERVICECODES_BY_SP](context,data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/service-codes/"+data[0].client_id,data)
          .then(({ data }) => {
            resolve(data);
            // context.commit(SET_SERVICECODES_BY_SP, data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
};

const mutations = {
  [SET_SERVICEORI](state, value) {
    state.serviceori = value;
  },
  [SET_SERVICECODES](state, value) {
    state.servicecode = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
