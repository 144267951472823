import ApiService from "@/core/services/api.service";
// import moment from "moment";
import JwtService from "@/core/services/jwt.service";

import {
  CHECK_F_ABBREVIATION_CODE,
  CHECK_F_ARCHIVAL_ABBREVIATION_CODE,
  FETCH_F_CLIENTS,
  FETCH_F_CLIENT_RATE,
  FETCH_F_CLIENT_ARCHIVAL_RATES,
  FETCH_SCHEDULE_TIMES,
  FETCH_CLIENT_APPOINTMENT,
  FETCH_CLIENT_ARCHIVAL_APPOINTMENT,
  FETCH_EXPIRY_ARCHIVAL_APPOINTMENT,
  UPDATE_SCHEDULE_STATUS,
  FETCH_SCHEDULE_TIMES_FOR_BACKEND,
  FETCH_CLIENT_ENROLLMENT,
  CHECK_F_SERVICE_ORI_CODE,
  CHECK_F_PROMO_CODE,
  DOWNLOAD_CLIENT_ENROLLMENT,
} from "../actions.type";
import {
  SET_F_CLIENTS,
  SET_ERROR,
  SET_F_CLIENT_NAME,
  SET_F_CLIENT_RATE,
  SET_F_ALERT_MSG,
  SET_RATE_TOTAL_AMOUNT,
  SET_ADD_RATE_QTY,
  SET_ARCHIVAL_PERIOD_ID,
  SET_CHECK_F_ABBREVIATION_CODE,
  UPDATE_ABBREVIATION_CODE,
  SET_SCHEDULE_TIMES,
  SET_CLIENT_ID,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_TIME,
  SET_SERVICE_ID,
  SET_CLIENT_APPOINTMENT,
  SET_LOADING_SPINNER,
  SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
  SET_CLIENT_SLUG,
  SET_BOOK_NOW_FROM_SP,
  SET_QTY,
  SET_MULTI_RECIPIENT,
  SET_IS_DRUG_TESTING,
  ISHEARTSPAYMENT,
  SET_IS_SERVICE_BY_MAIL,
  SET_SHOW_CAPTURE_OPTION,
  SET_ENABLE_ORI_RATE,
  SET_F_ARCHIVAL_CLIENT_RATE,
  SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
  SET_TOTAL_SERVICE_AMOUNT,
  SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
  SET_SERVICE_TYPE_CODE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  UPDATE_SERVICE_ORI_CODE,
  SET_CHECK_F_PROMO_CODE,
  UPDATE_PROMO_CODE,
  SET_PROMOCODE_DISCOUNT_AMOUNT,
  SET_PROMOCODE_DISCOUNT_PERCENTAGE,
  SET_WIZARD_FORM_BUTTON_DISABLE,
} from "../mutations.type";

const state = {
  wizardFormButtonDisable: false,
  fClients: [],
  clientName: "",
  displayName: "",
  clientFRate: {
    rate: "0",
    additional_rate: "0",
    service: {
      default_qty: 1,
    },
  },
  clientArchivalFRate: [],
  alertMsg: "",
  totalRateAmount: 0,
  addRateQty: 0,
  abbreviationCode: {
    data: {
      show_payment_option: true,
    },
    success: "none",
  },
  promoCode: {
    data: {
      discount_percentage: 0,
    },
    success: "none",
  },
  serviceTypeCode: "",
  inputAbbreviationCOde: "",
  inputPromoCode: "",
  frontScheduleTimes: [],
  clientId: 0,
  frontAppointmentDate: "",
  frontAppointmentTime: null,
  serviceId: 0,
  clientappoiments: [],
  loadingData: false,
  enable_on_site_payment: false,
  client_slug: "",
  is_book_now_from_sp: false,
  qty: 1,
  multi_recipient: 0,
  is_drug_testing: false,
  is_hearts_payment: false,
  is_service_by_mail: false,
  archival_period: "",
  totalArchivalRateAmount: 0,
  totalServiceAmount: 0,
  totalAdditionalAmount: 0,
  promocodeDiscountAmount: 0,
  inputServiceOriCode: "",
  promocodeDiscountRate: 0,
  show_capture_option: false,
  enable_ori_rate: false,
  ori: {
    data: {
      id: "",
    },
    success: "none",
    msg: "ORI is required",
  },
};
const getters = {
  getWizardFormButtonDisable(state) {
    return state.wizardFormButtonDisable;
  },
  getTotalAdditionalAmount(state) {
    return state.totalAdditionalAmount;
  },
  getServiceTypeCode(state) {
    return state.serviceTypeCode;
  },
  getTotalServiceAmount(state) {
    return state.totalServiceAmount;
  },
  getClientSlug(state) {
    return state.client_slug;
  },
  getIsHeartsPayment(state) {
    return state.is_hearts_payment;
  },
  getIsServiceByMail(state) {
    return state.is_service_by_mail;
  },
  getQty(state) {
    return state.qty;
  },
  getIsDrugTesting(state) {
    return state.is_drug_testing;
  },
  getMultirecipient(state) {
    return state.multi_recipient;
  },
  getShowCaptureOption(state) {
    return state.show_capture_option;
  },
  getEnableOriRate(state) {
    return state.enable_ori_rate;
  },
  getLoadingData(state) {
    return state.loadingData;
  },
  getfClients(state) {
    return state.fClients;
  },
  getfClientDisplayName(state) {
    return state.displayName;
  },
  getfClientName(state) {
    return state.clientName;
  },
  getfClientRate(state) {
    return state.clientFRate;
  },
  getArchivalPeriodId(state) {
    return state.archival_period;
  },
  getfAlertMsg(state) {
    return state.alertMsg;
  },
  getTotalRateAmount(state) {
    return state.totalRateAmount;
  },
  getAddQty(state) {
    return state.addRateQty;
  },
  getAbbreviationCode(state) {
    return state.abbreviationCode;
  },
  updateAbbreviationCode(state) {
    return state.inputAbbreviationCOde;
  },
  getFrontScheduleTimes(state) {
    return state.frontScheduleTimes;
  },
  getClientId(state) {
    return state.clientId;
  },
  getfrontAppointmentDate(state) {
    return state.frontAppointmentDate;
  },
  getfrontAppointmentTime(state) {
    return state.frontAppointmentTime;
  },
  getServiceId(state) {
    return state.serviceId;
  },

  isBookNowFromSp(state) {
    return state.is_book_now_from_sp;
  },
  getclientappoiments(state) {
    return state.clientappoiments;
  },
  getClientEnableOnSitePayment(state) {
    return state.enable_on_site_payment;
  },
  getArchivalRates(state) {
    return state.clientArchivalFRate;
  },
  getTotalArchivalRateAmount(state) {
    return state.totalArchivalRateAmount;
  },
  getTotalDiscountAmount(state) {
    return state.promocodeDiscountAmount;
  },
  getServiceOriCode(state) {
    return state.ori;
  },
  updateServiceOriCode(state) {
    return state.inputServiceOriCode;
  },
  updatePromoCode(state) {
    return state.inputPromoCode;
  },
  getPromoCode(state) {
    return state.promoCode;
  },
  getPromoCodeDiscountRate(state) {
    return state.promocodeDiscountRate;
  },
};
const actions = {
  [FETCH_F_CLIENTS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        "f/location/s?slug=" +
          data.slug +
          "&lat=" +
          data.center.lat +
          "&lng=" +
          data.center.lng +
          "&client_slug=" +
          data.client_slug
      )
        .then(({ data }) => {
          context.commit(SET_F_CLIENTS, data);
          resolve(data);
        })
        .catch(({ err }) => {
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [FETCH_F_CLIENT_RATE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("client/rate/f", data)
        .then(({ data }) => {
          resolve(data.data);
          context.commit(SET_F_CLIENT_RATE, data.data);
          context.commit(SET_F_ALERT_MSG, data.alertMsg);
        })
        .catch(({ err }) => {
          reject(err);
          context.commit(SET_ERROR, err);
        });
    });
  },

  [FETCH_F_CLIENT_ARCHIVAL_RATES](context, data) {
    return new Promise((resolve, reject) => {
      var _data = {
        id: data,
      };
      ApiService.post("get/archival-period/list/f/", _data)

        .then(({ data }) => {
          resolve(data.data);
          context.commit(SET_F_ARCHIVAL_CLIENT_RATE, data.data);
          context.commit(SET_F_ALERT_MSG, data.alertMsg);
        })
        .catch(({ err }) => {
          reject(err);
          context.commit(SET_ERROR, err);
        });
    });
  },
  [CHECK_F_ABBREVIATION_CODE](context, data) {
    return new Promise((resolve, reject) => {
      var data_ = data;
      ApiService.post("check/abbreviation_code/", data_)
        .then(({ data }) => {
          resolve(data);
          // if(data.succes){
          //   context.commit(UPDATE_ABBREVIATION_CODE, '');
          // }
          if (data.data.show_payment_option != undefined) {
            context.commit(SET_CHECK_F_ABBREVIATION_CODE, data);
          } else {
            context.commit(SET_CHECK_F_ABBREVIATION_CODE, {
              data: {
                show_payment_option: true,
              },
              success: false,
              msg: data.msg,
            });
          }
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [CHECK_F_ARCHIVAL_ABBREVIATION_CODE](context, data) {
    return new Promise((resolve, reject) => {
      var data_ = data;
      ApiService.post("check/archival/abbreviation_code/", data_)
        .then(({ data }) => {
          resolve(data);
          // if(data.succes){
          //   context.commit(UPDATE_ABBREVIATION_CODE, '');
          // }
          if (data.data.show_payment_option != undefined) {
            context.commit(SET_CHECK_F_ABBREVIATION_CODE, data);
          } else {
            context.commit(SET_CHECK_F_ABBREVIATION_CODE, {
              data: {
                show_payment_option: true,
              },
              success: false,
              msg: data.msg,
            });
          }
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [CHECK_F_SERVICE_ORI_CODE](context, data) {
    return new Promise((resolve, reject) => {
      var data_ = data;
      ApiService.post("check/service_ori/", data_)
        .then(({ data }) => {
          resolve(data);
          if (data.success) {
            context.commit(SET_CHECK_F_SERVICE_ORI_CODE, data);
          } else {
            context.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
              data: {
                id: "",
              },
              success: false,
              msg: data.msg,
            });
          }
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },
  [CHECK_F_PROMO_CODE](context, data) {
    return new Promise((resolve, reject) => {
      var code = {
        code: data.code.trim(),
        slug: data.client_slug,
      };
      ApiService.post("check/promo_code/", code)
        .then(({ data }) => {
          resolve(data);
          // if(data.succes){
          //   context.commit(UPDATE_ABBREVIATION_CODE, '');
          // }
          if (data) {
            context.commit(SET_CHECK_F_PROMO_CODE, data);
          } else {
            context.commit(SET_CHECK_F_PROMO_CODE, {
              data: {
                discount_percentage: data.discount_percentage,
              },
              success: false,
              msg: data.msg,
            });
          }
        })
        .catch(({ err }) => {
          reject(err);
        });
    });
  },

  [UPDATE_SCHEDULE_STATUS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("schedule/status-change/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SCHEDULE_TIMES](context, data) {
    var data_ = data;
    context.commit(SET_LOADING_SPINNER, true);
    context.commit(SET_SCHEDULE_TIMES, []);
    return new Promise((resolve, reject) => {
      ApiService.post("get/schedule-list/", data_)
        .then(({ data }) => {
          resolve(data);
          // var currentDateTime = moment(new Date())
          //   .tz(data_.time_zone)
          //   .format("YYYY-MM-DD");
          // data.data.forEach(element => {
          //   var sDate = moment(element.schedule_date)
          //     .tz(data_.time_zone)
          //     .format("YYYY-MM-DD");
          // if (moment(currentDateTime).isSame(sDate)) {
          // var nowTime = moment(new Date()).format("hh:mm a");
          // console.log(nowTime);
          // var dateStartTime = moment(
          //   String(element.schedule_date + " " + element.start_time)
          // ).format("hh:mm a");
          // console.log(dateStartTime);
          // var dateEndTime = moment
          //   .utc(String(element.schedule_date + " " + element.end_time))
          //   .local()
          //   .format("h:mma");
          // var beginningTime = moment(
          //   moment(new Date()).format("h:mma"),
          //   "h:mma"
          // );
          // var a = moment.utc(new Date(), "YYYYMMDD HH:mm:ss");
          // var beginningTime_ = a
          //   .clone()
          //   .tz(data_.time_zone)
          //   .format("YYYY-MM-DD HH:mm:ss");
          // var beginningTime = moment(
          //   moment(beginningTime_).format("h:mma"),
          //   "h:mma"
          // );
          // var endTime = moment(
          //   moment(element.schedule_date + " " + element.start_time).format(
          //     "h:mma"
          //   ),
          //   "h:mma"
          // );
          // console.log(endTime, beginningTime);
          // var endTime = moment(dateEndTime, "h:mma");
          // if (endTime.isBefore(beginningTime)) {
          //   element.show_plan = 0;
          // }
          // }

          // var startDateTime = element.schedule_date + ' ' + element.start_time
          // });
          context.commit(SET_SCHEDULE_TIMES, data.data);
          context.commit(SET_LOADING_SPINNER, false);
        })
        .catch(({ err }) => {
          context.commit(SET_LOADING_SPINNER, false);
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [FETCH_SCHEDULE_TIMES_FOR_BACKEND](context, data) {
    var data_ = data;
    context.commit(SET_LOADING_SPINNER, true);
    context.commit(SET_SCHEDULE_TIMES, []);
    return new Promise((resolve, reject) => {
      ApiService.post("get/reschedule-appointments/list/", data_)
        .then(({ data }) => {
          resolve(data);
          // var currentDateTime = moment(new Date()).format("YYYY-MM-DD");
          // data.data.forEach(element => {
          //   var sDate = moment(element.schedule_date).format("YYYY-MM-DD");
          //   if (moment(currentDateTime).isSame(sDate)) {
          //     // var nowTime = moment(new Date()).format("hh:mm a");
          //     // console.log(nowTime);
          //     // var dateStartTime = moment(
          //     //   String(element.schedule_date + " " + element.start_time)
          //     // ).format("hh:mm a");
          //     // console.log(dateStartTime);
          //     // var dateEndTime = moment
          //     //   .utc(String(element.schedule_date + " " + element.end_time))
          //     //   .local()
          //     //   .format("h:mma");

          //     // var beginningTime = moment(
          //     //   moment(new Date()).format("h:mma"),
          //     //   "h:mma"
          //     // );

          //     var a = moment.utc(new Date(), "YYYYMMDD HH:mm:ss");
          //     var beginningTime_ = a
          //       .clone()
          //       .tz(data_.time_zone)
          //       .format("YYYY-MM-DD HH:mm:ss");

          //     var beginningTime = moment(
          //       moment(beginningTime_).format("h:mma"),
          //       "h:mma"
          //     );

          //     var endTime = moment(
          //       moment(element.schedule_date + " " + element.start_time).format(
          //         "h:mma"
          //       ),
          //       "h:mma"
          //     );

          //     // console.log(endTime, beginningTime);
          //     // var endTime = moment(dateEndTime, "h:mma");
          //     if (endTime.isBefore(beginningTime)) {
          //       element.show_plan = 0;
          //     }
          //   }

          //   // var startDateTime = element.schedule_date + ' ' + element.start_time
          // });
          context.commit(SET_SCHEDULE_TIMES, data.data);
          context.commit(SET_LOADING_SPINNER, false);
        })
        .catch(({ err }) => {
          context.commit(SET_LOADING_SPINNER, false);
          context.commit(SET_ERROR, err);
          reject(err);
        });
    });
  },
  [FETCH_CLIENT_ENROLLMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("get/enrollment/list/?page=" + data.page, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [DOWNLOAD_CLIENT_ENROLLMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("download/report/store-forward/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },

  [FETCH_CLIENT_ARCHIVAL_APPOINTMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("get/archival-list/?page=" + data.page, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [FETCH_EXPIRY_ARCHIVAL_APPOINTMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("get/archival-list/near-expiration/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },

  [FETCH_CLIENT_APPOINTMENT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "appointments/" + data.type + "/list/?page=" + data.page,
          data
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
};
const mutations = {
  [SET_CLIENT_SLUG](state, slug) {
    state.client_slug = slug;
  },
  [SET_QTY](state, qty) {
    state.qty = qty;
  },
  [SET_MULTI_RECIPIENT](state, val) {
    state.multi_recipient = val;
  },
  [SET_IS_DRUG_TESTING](state, val) {
    state.is_drug_testing = val;
  },
  [SET_LOADING_SPINNER](state, flag) {
    state.loadingData = flag;
  },
  [SET_F_CLIENTS](state, data) {
    state.fClients = data;
  },
  [SET_F_CLIENT_NAME](state, name) {
    state.clientName = name;
  },
  [SET_F_CLIENT_RATE](state, data) {
    state.clientFRate = data;
  },

  [SET_F_ARCHIVAL_CLIENT_RATE](state, data) {
    state.clientArchivalFRate = data;
  },
  [SET_F_ALERT_MSG](state, msg) {
    state.alertMsg = msg;
  },
  [SET_ARCHIVAL_PERIOD_ID](state, data) {
    state.archival_period = data;
  },

  [SET_RATE_TOTAL_AMOUNT](state, value) {
    state.totalRateAmount = value;
  },
  [SET_TOTAL_SERVICE_AMOUNT](state, value) {
    state.totalServiceAmount = value;
  },
  [SET_ADDITIONAL_CARD_TOTAL_AMOUNT](state, value) {
    state.totalAdditionalAmount = value;
  },
  [SET_ADD_RATE_QTY](state, value) {
    state.addRateQty = value;
  },
  [SET_CHECK_F_ABBREVIATION_CODE](state, data) {
    state.abbreviationCode = data;
  },
  [SET_CHECK_F_SERVICE_ORI_CODE](state, data) {
    state.ori = data;
  },
  [SET_CHECK_F_PROMO_CODE](state, data) {
    state.promoCode = data;
  },
  [UPDATE_ABBREVIATION_CODE](state, value) {
    state.inputAbbreviationCOde = value;
  },
  [UPDATE_PROMO_CODE](state, value) {
    state.inputPromoCode = value;
  },
  [UPDATE_SERVICE_ORI_CODE](state, value) {
    state.inputServiceOriCode = value;
  },
  [SET_SCHEDULE_TIMES](state, data) {
    state.frontScheduleTimes = data;
  },
  [SET_CLIENT_ID](state, value) {
    state.clientId = value;
  },
  [SET_APPOINTMENT_DATE](state, value) {
    state.frontAppointmentDate = value;
  },
  [SET_APPOINTMENT_TIME](state, data) {
    state.frontAppointmentTime = data;
  },
  [SET_SERVICE_ID](state, value) {
    state.serviceId = value;
  },
  [SET_SERVICE_TYPE_CODE](state, value) {
    state.serviceTypeCode = value;
  },
  [SET_CLIENT_APPOINTMENT](state, data) {
    state.clientappoiments = data;
  },
  [SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT](state, flag) {
    state.enable_on_site_payment = flag;
  },
  [SET_BOOK_NOW_FROM_SP](state, flag) {
    state.is_book_now_from_sp = flag;
  },
  [ISHEARTSPAYMENT](state, data) {
    state.is_hearts_payment = data;
  },
  [SET_IS_SERVICE_BY_MAIL](state, flag) {
    state.is_service_by_mail = flag;
  },
  [SET_SHOW_CAPTURE_OPTION](state, flag) {
    state.show_capture_option = flag;
  },
  [SET_ARCHIVAL_TOTAL_RATE_AMOUNT](state, data) {
    state.totalArchivalRateAmount = data;
  },
  [SET_PROMOCODE_DISCOUNT_AMOUNT](state, data) {
    state.promocodeDiscountAmount = data;
  },
  [SET_PROMOCODE_DISCOUNT_PERCENTAGE](state, data) {
    state.promocodeDiscountRate = data;
  },
  [SET_WIZARD_FORM_BUTTON_DISABLE](state, value) {
    state.wizardFormButtonDisable = value;
  },
  [SET_ENABLE_ORI_RATE](state, value) {
    state.enable_ori_rate = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
