import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import { STORE_RESCHEDULE_TIMES } from "../actions.type";
// mutation types
import { SET_APPOINTMENT_FOR_RESCHEDULE } from "../mutations.type";

const state = {
  selectedappointment: null
};

const getters = {
  getAppointmentRescheduleDetail(state) {
    return state.selectedappointment;
  }
};

const actions = {
  [STORE_RESCHEDULE_TIMES](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          "reschedule/appointment/" + data.token + "/" + data.app_id + "/",
          data
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};

const mutations = {
  [SET_APPOINTMENT_FOR_RESCHEDULE](state, data) {
    state.selectedappointment = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
